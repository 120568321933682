import { ref, Ref } from 'vue';
import { ListHeaderItem } from '@/components/common/list';
import HttpRequest from '@/util/axios.config';
import {
    ModelType, VersionType
} from './all.type';

const getVersionMng = (callback: Function) => {
    HttpRequest.get('versionmanageall', {}, callback);
};

const getVersionList = (model: string, callback: Function) => {
    HttpRequest.get('getversionlist', {
        Model: model
    }, callback);
};

const header: Array<ListHeaderItem> = [{
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation
}, {
    name: 'Name',
    label: WordList.TabelPersonDeviceInHtmlOwner
}, {
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Firmware',
    label: WordList.MulListUpdateListTanleFirmware
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}];

const rules = {
    Version: [
        { required: true, message: WordList.RuleVersionSelect, trigger: 'blur' }
    ],
    UpdateTimeDate: [
        { required: true, message: WordList.RuleVersionTime, trigger: 'blur' }
    ]
};

function initVersion(model: string, updateType: 'add' | 'modify') {
    // 获取可选择型号，用作下拉框初始值
    const modelOptions: Ref<Array<ModelType>> = ref([]);
    getVersionMng((res: {
        data: Array<ModelType>;
    }) => {
        modelOptions.value = res.data.sort((obj1, obj2) => obj1.VersionName.localeCompare(obj2.VersionName));
    });

    // 获取对应型号版本
    const versionOptions: Ref<Array<VersionType>> = ref([]);

    const changeVersionOptions = (selectModel: string) => {
        getVersionList(selectModel, (res: {
            data: Array<VersionType>;
        }) => {
            versionOptions.value = res.data.sort((obj1, obj2) => obj2.Version.localeCompare(obj1.Version));
        });
    };
    if (updateType === 'modify') {
        changeVersionOptions(model);
    }
    return {
        modelOptions,
        versionOptions,
        changeVersionOptions
    };
}

export default null;
export {
    initVersion,
    header,
    rules
};