
import {
    defineComponent,
    ref,
    Ref,
    watch,
    computed,
    PropType
} from 'vue';
import HttpRequest from '@/util/axios.config';
import { update } from '@/data';
import { list, RequestData } from '@/components/common/list';
import { format } from '@/methods/date';
import { isCommunity, isOffice } from '@/methods/role';
import {
    InitData
} from './all.type';
import {
    header,
    rules,
    initVersion
} from './util';
import SubmitNotice from './submitNotice.vue';

const inputPlaceHolder = `${WordList.TabelPersonDeviceInHtmlMAC}/${WordList.TabelPersonDeviceInHtmlOwner}/${WordList.TabelPersonDeviceInHtmlLocation}`;

export default defineComponent({
    components: {
        list,
        SubmitNotice
    },
    props: {
        updateType: {
            type: String as PropType<'add'|'modify'>,
            required: true
        },
        initData: {
            type: Object as PropType<InitData>,
            default: () => ({
                Model: '',
                UpdateTime: new Date(),
                Device: [''],
                Version: '',
                ID: ''
            })
        },
        initChoose: {
            type: Array as PropType<Array<string>>,
            default: () => ['']
        }
    },
    setup(props, { emit }) {
        const form: Ref<any> = ref(null);
        const updateTimeType = ref('0');
        const updateToList = ref(0);

        const dialogTitle = props.updateType === 'add' ? WordList.TabelUpdateBoxTitleAdd : WordList.TabelUpdateBoxTitleModify;

        const initForm = ref({
            Model: props.initData.Model,
            Version: props.initData.Version,
            UpdateTimeDate: props.initData.UpdateTime,
            UpdateTime: '',
            Device: props.initData.Device,
            ID: props.initData.ID
        });

        // 获取所有个人设备
        let type = '';
        if (isCommunity()) {
            type = 'community';
        } else if (isOffice()) {
            type = 'office';
        } else {
            type = 'single';
        }
        const listRequestData: Ref<RequestData> = ref({
            url: `v3/web/${type}/device/getAllForUpgrade`,
            param: {
                searchValue: '',
                UnSelect: props.initData.Version
            }
        });

        // 获取可选择型号，用作下拉框初始值
        const { modelOptions, versionOptions, changeVersionOptions } = initVersion(initForm.value.Model, props.updateType);
        watch(versionOptions, () => {
            if (props.updateType === 'add') {
                initForm.value.Version = versionOptions.value.length ? versionOptions.value[0].Version : '';
            } else {
                setChangeLog();
                const chooseVersionOption = versionOptions.value.find((option) => option.Version === initForm.value.Version);
                initForm.value.Version = chooseVersionOption ? chooseVersionOption.Version : '';
            }
        });

        // 获取设备
        const getSelectDevices = (idArr: Array<string>, valArr: Array<{
            MAC: string;
        }>) => {
            const macArr: Array<string> = [];
            valArr.forEach((item) => {
                macArr.push(item.MAC);
            });
            initForm.value.Device = macArr;
        };

        // 监听搜索框，刷新列表
        // @ts-ignore
        let timer: NodeJS.Timeout;
        watch(computed(() => listRequestData.value.param.searchValue), () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                updateToList.value += 1;
            }, 1000);
        });

        // v6.5 新增查看changeLog需求 2022/7/27
        const changeLog = ref('');
        // v6.5 修改编辑时候两个监听导致的bug 2022/9/1
        function setChangeLog() {
            // v6.5 新增查看changeLog需求 2022/7/27
            const chooseVersionOption = versionOptions.value.find((option) => option.Version === initForm.value.Version);
            changeLog.value = chooseVersionOption ? chooseVersionOption.Log : '';
        }

        // 监听版本下拉框选择
        watch(computed(() => initForm.value.Version), (version) => {
            if (version) {
                setChangeLog();
            }
            listRequestData.value.param.UnSelect = initForm.value.Version;
            updateToList.value += 1;
        }, {
            immediate: true
        });

        const showDeviceTips = ref(false);
        // v6.5 新增二次确认弹窗 2022/7/28 zh
        const showSubmitNotice = ref(false);
        const changeShowSubmitNotice = () => {
            showDeviceTips.value = initForm.value.Device.length === 0;
            form.value.validate((valid: boolean) => {
                if (!valid) return false;
                if (showDeviceTips.value) return false;
                showSubmitNotice.value = true;
                return true;
            });
        };

        const submit = () => {
            showSubmitNotice.value = false;
            initForm.value.UpdateTime = format(initForm.value.UpdateTimeDate, 'YYYY-MM-DD HH:mm:ss');
            const url = props.updateType === 'modify' ? 'modupdatecase' : 'addupdatecase';
            HttpRequest.post(url, initForm.value, () => {
                emit('close');
                emit('success');
            });
        };

        return {
            dialogTitle,
            initForm,
            modelOptions,
            versionOptions,
            form,
            submit,
            rules,
            inputPlaceHolder,
            header,
            listRequestData,
            updateTimeType,
            update,
            updateToList,
            showDeviceTips,
            getSelectDevices,
            changeVersionOptions,
            changeLog,
            showSubmitNotice,
            changeShowSubmitNotice
        };
    }
});
